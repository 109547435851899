<template>
    <h2>SIGMA TAU GAMMA: GRAPHICS CHAIR</h2>
    <div class="content">
        <p style="text-align:center">A gallery of my work as Graphics Chair for Sigma Tau Gamma Fraternity at Northwest Missouri State University.</p><br>
        <div class="container" id="sigtau">
            <div class="item"><img src="../assets/st1.png"></div>
            <div class="item"><img src="../assets/st3.png"></div>
            <div class="item"><img src="../assets/st4.png"></div>
            <div class="item"><img src="../assets/st7.png"></div>
            <div class="item"><img src="../assets/st6.png"></div>
            <div class="item"><img src="../assets/st5.png"></div>
            <div class="item"><img src="../assets/st8.png"></div>
            <div class="item"><img src="../assets/st9.png"></div>
            <div class="item"><img src="../assets/st10.png"></div>
            <div class="item"><img src="../assets/st11.png"></div>
            <div class="item"><img src="../assets/st12.png"></div>
            <div class="item"><img src="../assets/st13.png"></div>
            <div class="item"><img src="../assets/st14.png"></div>
        </div>
    </div>
</template>
<style scoped>
.item > img {
    width:100%;
    border-radius:8px;
    display: block;
}
</style>
